import consumer from "./consumer";

document.addEventListener("turbolinks:load", function() {
  var currentUserId = $('#current-user').data('user-id');
  if (!currentUserId) {
    return;
  }

  const lastSeenElement = document.querySelector(`#user-${currentUserId}-last-seen`);
  if (lastSeenElement && !lastSeenElement.classList.contains('active')) {
    lastSeenElement.classList.add('active');
  }

  consumer.subscriptions.create({channel: "UserChannel", user_id: currentUserId}, {
    connected() {
      console.log("connected...");
      if (lastSeenElement && !lastSeenElement.classList.contains('active')) {
        lastSeenElement.classList.add('active');
      }
    },

    disconnected() {
      console.log("Disconnected from UserChannel.");
      if (lastSeenElement && lastSeenElement.classList.contains('active')) {
        lastSeenElement.classList.remove('active');
      }
    },

    received(data) {
      if (data.last_seen_at) {
        const lastSeenElements = document.querySelectorAll(`#user-${data.user_id}-last-seen`);
        lastSeenElements.forEach(lastSeenElement => {
          if (data.online === true) {
            lastSeenElement.classList.add('active');
          } else {
            lastSeenElement.classList.remove('active');
          }
        });
      }
    }
  });
});
