import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
  var currentUserId = $('#current-user').data('user-id');
  var currentUserType = ($('#current-user').data('user-type') === "") ? "SenderProfile" : "ShipperProfile";
  var room_ids = []
  var result=document.querySelectorAll('[data-room-id]');
  for (var index in result){
    if (result.hasOwnProperty(index)){
      room_ids.push(result[index].getAttribute('data-room-id'))
    }
  }
  $.each(room_ids, function(index, room_id) {

    consumer.subscriptions.create({channel: "RoomChannel", room_id: room_id}, {
      connected() {
        console.log("connected...");
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        const roomId = data.room_id;
        const latestMessage = data.message;

        const unreadCountElement = $(`[data-room-id="${roomId}"] .dot-count`);
        const currentCount = parseInt(unreadCountElement.text()) || 0;

        const roomMessageContainer = $(`#room-${data.room_id}`);
        const isSender = data.sender_profile_id === currentUserId && data.sender_profile_type === currentUserType;
        const messageClass = isSender ? 'message-sender' : 'message-receiver';
        const flexDirection = isSender ? 'row-reverse' : 'row';
        const username = data.sender_initials;
        const alignItems = isSender ? 'flex-end' : 'flex-start';
        const timestampAlign = isSender ? 'right' : 'left';
        const timestampMargin = isSender ? 'margin-right: 80px;' : 'margin-left: 80px;';
        document.getElementById('file-preview-container').classList.add('d-none');

        const attachmentHtml = data.attachment && data.attachment.content_type ? `
          <div class="attachment-container" style="margin-top: 5px;">
            ${data.attachment.content_type.startsWith('image/') ? `
              <div class="img-preview-container">
                <img class="img-preview" src="${data.attachment.url}" alt="${data.attachment.filename}" style="display: block;">
              </div>
            ` : data.attachment.content_type.startsWith('video/') ? `
              <div class="video-preview-container">
                <video controls class="video-preview">
                  <source src="${data.attachment.url}" type="${data.attachment.content_type}">
                  Your browser does not support the video tag.
                </video>
              </div>
            ` : `
              <div class="file-link-container">
                <a href="${data.attachment.url}" target="_blank" class="file-link">${data.attachment.filename}</a>
              </div>
            `}
          </div>
        ` : '';

        if (data.attachment && data.attachment.content_type) {
          setTimeout(() => {
            addMessageToRoom();
          }, 3000);
        } else {
          addMessageToRoom();
        }
        function isValidUrl(url) {
          const pattern = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;
          return pattern.test(url);
        }
        function truncateText(text, maxLength) {
          return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
        }
        function addMessageToRoom() {
          if ($(`#message-${data.message_id}`).length > 0) {
            return;
          }
          const truncatedMessage = truncateText(latestMessage, 50);
          $(`[data-room-id="${roomId}"] .mb-0`).text(truncatedMessage);

          roomMessageContainer.append(`
            <div class="message ${messageClass}" id="message-${data.message_id}">
              <div class="align-items-end">
                <div class="message-row" style="display: flex; flex-direction: ${flexDirection}; align-items: ${alignItems};">
                  <div class="message-img-holder">
                  <div class="img-fluid shadow rounded-circle" style="display: inline-block;">
                  <span class="default-profile">
                    ${isValidUrl(data.sender_profile_pic) 
                      ? `<img class="img-fluid default-profile" src="${data.sender_profile_pic}" alt="Profile Picture">` 
                      : `${username}`}
                  </span>
                  </div>
                  </div>
                  <div class="message-content-container" style="display: flex; flex-direction: column; align-items: ${alignItems}; margin-${isSender ? 'right' : 'left'}: 10px;">
                    ${data.message ? `<p class="message-content" style="margin: 0;">${data.message}</p>` : ''}
                    ${attachmentHtml}
                  </div>
                </div>
                <div class="message-timestamp-container" style="text-align: ${timestampAlign}; margin-top: 5px; ${timestampMargin}">
                  <span class="f-12 text-muted message-timestamp">${data.timestamp}</span>
                </div>
              </div>
            </div>
          `);

          const newCount = isSender ? currentCount : currentCount + 1;
          unreadCountElement.text(newCount);
          if (newCount > 0) unreadCountElement.show();
          if (parseInt(unreadCountElement.text()) === 0) unreadCountElement.hide();

          $('.message-type-box').scrollTop($('.message-type-box').prop("scrollHeight"));
          $('#message_content').val('');
          $('#file-preview-container').html(''); 
          $('#upload').val('');
          $(`#last-message-date-${roomId}`).text(data.last_message_date);
          $('#file-preview-container').addClass('d-none');
        }
      }
    });
  });

  // Hide notifications after 2 seconds
  $(".alert-holder").fadeTo(2000, 500).slideUp(500, function() {
    $(".alert-holder").slideUp(500);
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.navbar-collapse').length && !$(event.target).is('.navbar-toggler')) {
      if($('.navbar-collapse').hasClass('show')) {
          $('.navbar-collapse').removeClass('show');
      }
    }           
  });

  $('.default-profile').click(function () {
    $('.navbar-collapse').removeClass('show');
  });
});

$(document).on('turbolinks:load', function() {
  const dataTableInfo = $('.dataTables_info');
  if (dataTableInfo) {
    dataTableInfo.remove();
  }
  // Per page item selection for sender active orders dashboard.
  $('#senderDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/dashboard",
        data: { pagy_items_count: selectedValue }   
      });
    }
  });
  // Per page item selection for sender Pending orders dashboard.
  $('#senderDashboardPendingCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/dashboard",
        data: { pagy_items_pending_count: selectedValue }   
      });
    }
  });

  // Per page item selection for Forwarder Dashboard Pending orders.
  $('#ForwarderDashboardPendingCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/forwarders/dashboards",
        data: { forwarder_items_pending_count: selectedValue }   
      });
    }
  });

   // Per page item selection for Forwarder Dashboard Active orders.
  $('#ForwarderDashboardActiveCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/forwarders/dashboards",
        data: { forwarder_items_active_count: selectedValue }   
      });
    }
  });

  $('#TravelersDashboardPendingCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/travelers/dashboards",
        data: { travelers_items_pending_count: selectedValue }   
      });
    }
  });
  $('#TravelersDashboardActiveCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/travelers/dashboards",
        data: { travelers_items_active_count: selectedValue }   
      });
    }
  });
  $('#PurchaseOrdersItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/travelers/orders",
        data: { trav_purchase_items_active_count: selectedValue }   
      });
    }
  });
  


  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  $('#travelersDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/travelers/orders",
        data: { pagy_items_count: selectedValue, status: statusParam}
      });
    }else{
      if (selectedValue){
        $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/travelers/orders",
        data: { pagy_items_count: selectedValue}
      });
      }
    }
  });
  
  $('#publicTripItemsCount').change(function() {
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/public/trips",
        data: { pagy_items_count: selectedValue }   
      });
    }
  });

  $("#FeedbackItemsCount").change(function () {
    // Get the selected value
    var selectedValue = $(this).val();
    var userRole = $(this).data('name')
    if (selectedValue && userRole) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: "script",
        url: `/users/feedbacks/${userRole}`,
        data: { pagy_items_count: selectedValue },
      });
    }
  });
  
  // Per page item selection for sender company trips dashboard.
  $('#senderDashboardCompanyItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/dashboard",
        data: { pagy_company_items_count: selectedValue, status: statusParam }   
      });
    }else{
      if(selectedValue) {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          dataType: 'script',  
          url: "/senders/dashboard",
          data: { pagy_company_items_count: selectedValue }   
        });
      }

    }
  });

  // Per page item selection for sender order.
  $('#senderOrdersItemsCount').change(function() {
    console.log('hii');
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/orders",
        data: { pagy_items_count: selectedValue, status: statusParam }   
      });
    }else{
      if(selectedValue) {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          dataType: 'script',  
          url: "/senders/orders",
          data: { pagy_items_count: selectedValue}   
        });
      }
    }
  });

  $('#SenderPurchaseOrdersItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/senders/orders",
        data: { send_purchase_items_active_count: selectedValue }   
      });
    }else{
      if(selectedValue) {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          dataType: 'script',  
          url: "/senders/orders",
          data: { pagy_items_count: selectedValue}   
        });
      }
    }
  });

  $('#forwarderDashboardItemsCount').change(function() {
    // Get the selected value
    var selectedValue = $(this).val();
    const statusParam = getParameterByName('status');
    if(selectedValue && statusParam) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/forwarders/orders",
        data: { pagy_items_count: selectedValue, status: statusParam}
      });
    }else{
      if (selectedValue){
        $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',
        url: "/shippers/forwarders/orders",
        data: { pagy_items_count: selectedValue}
      });
      }
    }
  });
  $('#forwarderPurchaseItemsCount').change(function() {  
    // Get the selected value
    // var selectedValue = $(this).val();
    // const statusParam = getParameterByName('status');
    // if(selectedValue &&  statusParam) {
    //   $.ajax({
    //     type: "GET",
    //     contentType: "application/json",
    //     dataType: 'script',
    //     url: "/shippers/forwarders/orders",
    //     data: { for_pagy_items_count: selectedValue, status: statusParam}
    //   });
    // }else{
    //   if (selectedValue){
    //     $.ajax({
    //     type: "GET",
    //     contentType: "application/json",
    //     dataType: 'script',
    //     url: "/shippers/forwarders/orders",
    //     data: { pagy_items_count: selectedValue}
    //   });
    //   }
    // }
    // Get the selected value
    var selectedValue = $(this).val();
    if(selectedValue) {
      $.ajax({
        type: "GET",
        contentType: "application/json",
        dataType: 'script',  
        url: "/shippers/forwarders/orders",
        data: { for_pagy_items_count: selectedValue }   
      });
    }else{
      if(selectedValue) {
        $.ajax({
          type: "GET",
          contentType: "application/json",
          dataType: 'script',  
          url: "/shippers/forwarders/orders",
          data: { for_pagy_items_count: selectedValue}   
        });
      }
    }
  });

  // feedback rating
  $(".rating input:radio").attr("checked", false);

  $(".rating input").click(function () {
    $(".rating span").removeClass("checked");
    $(this).parent().addClass("checked");
  });

  $("input:radio").change(function () {
    var userRating = this.value;
    $("#feedback_rating").val(userRating);
  }); 
});